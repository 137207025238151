<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-07-27 14:44:52
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-09-12 14:35:51
 * @FilePath: \official_website\src\views\componets\News.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="news">
    <div class="trends">
      <div class="left">
        <div class="title">品信动态</div>
        <div class="description">探究行业热点，掌握最新动态</div>
        <div class="arr-right">
          <i class="iconfont icon-youjiantou" @click="tapNews"></i>
        </div>
      </div>
      <div class="right">
        <ul class="news-box">
          <li
            class="news-item"
            v-for="(item, index) in newsList"
            :key="index"
            @click="tapPages(item.id)"
          >
            <div class="new-left">
              <div class="new-cont">
                <div class="mounth">{{ item.month }} - {{ item.day }}</div>
                <div class="year">{{ item.year }}</div>
              </div>
            </div>
            <div class="new-right">
              <div class="title">{{ item.title }}</div>
              <div class="description">{{ item.summary }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
    
<script>
import api from "../../../api/api";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      newsList: [],
    };
  },
  created() {
    this.getFocusNews(6);
  },
  mounted() {},
  watch: {},
  methods: {
    tapPages(id) {
      this.$router.push({
        path: `/MediumDetail/${id}`,
      });
    },
    tapNews() {
       this.$router.push({
        path: "/medium"
      });
    },
    // 获取焦点新闻
    getFocusNews(num) {
      api
        .getFocusNews(num)
        .then((res) => {
          console.log(res.data);
          let result = res.data.models;
          result.forEach((item) => {
            let times = item.createTime ? item.createTime.split(" ")[0] : "";
            let moment = times ? times.split("-") : [];
            item.year = moment[0];
            item.month = moment[1];
            item.day = moment[2];
          });
          this.newsList = result;
        })
        .catch((error) => {});
    },
  },
};
</script>
    
<style lang="scss">
#news {
  width: 100%;
  height: 100%;
  .trends {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 8rem 8% 1.2rem;
    .left {
      width: 20%;
      .title {
        font-size: 2rem;
        margin-bottom: 1rem;
        font-family: "SourceHanSansSC-Medium";
      }
      .description {
        font-size: 1.2rem;
        width: 11.875rem;
        color: #444;
        margin-bottom: 4rem;
        font-family: "OPlusSans3-Regular";
      }
      .arr-right {
        i {
          font-size: 6rem;
          color: #0b847b;
          cursor: pointer;
        }
      }
    }
    .right {
      flex: 1;
      .news-box {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        .news-item {
          box-sizing: border-box;
          width: 48.5%;
          height: 30%;
          margin-right: 3%;
          margin-bottom: 1.5%;
          background: #ffffff;
          box-sizing: border-box;
          border: 1px solid #ededed;
          border-radius: 1rem;
          box-shadow: 0 0 10px 2px #eee;
          display: flex;
          transition: all 0.5s;
          cursor: pointer;
          .description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
          &:nth-of-type(2n) {
            margin-right: 0;
          }
          &:nth-child(5) {
            margin-bottom: 0;
          }
          &:nth-child(6) {
            margin-bottom: 0;
          }
          &:hover {
            background-image: url("../../../assets/image/home/news-bg.png");
            background-size: cover;
            background-position: center center;
            color: #fff;
            transform: scale(1.1);
            -ms-transform: scale(1.1);
            transition: all 1s ease;
            .year,
            .description {
              color: #fff !important;
            }
            .description {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
            }
          }

          .new-left {
            width: 30%;
            padding: 3%;
            .new-cont {
              border-bottom: 1px solid #dddddd;
              .mounth {
                font-size: 1.5rem;
                margin-bottom: 0.625rem;
                font-family: "SourceHanSansSC-Medium";
              }
              .year {
                font-size: 1rem;
                color: #444;
                margin-bottom: 2rem;
                font-family: "OPlusSans3-Regular";
              }
            }
          }
          .new-right {
            flex: 1;
            padding: 3%;
            .title {
              font-size: 1.2rem;
              margin-bottom: 5%;
              font-family: "SourceHanSansSC-Medium";
            }
            .description {
              font-size: 1rem;
              color: #666;
              font-family: "OPlusSans3-Regular";
            }
          }
        }
      }
    }
  }
}
</style>